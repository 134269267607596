import { resetTextAction } from 'actions/text/resetTextAction'
import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { appConfidentialityPath, confidentialityPath } from 'components/paths'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import React, { FC } from 'react'
import ConfidentialityIndex from '../Legal/Confidentiality/ConfidentialityIndex'

export const ConfidentialityBottomSheet: FC = () => {
  const dispatch = useAppDispatch()
  const baseUrl = useBaseUrl()

  const handleClosed = () => {
    dispatch(resetTextAction())
  }

  return (
    <>
      <BottomSheetOnRoute
        route={mergeAllUrls(baseUrl, appConfidentialityPath)}
        onClosed={handleClosed}
      >
        <ConfidentialityIndex redesign />
      </BottomSheetOnRoute>
      <BottomSheetOnRoute
        route={mergeAllUrls(baseUrl, confidentialityPath)}
        onClosed={handleClosed}
      >
        <ConfidentialityIndex redesign />
      </BottomSheetOnRoute>
    </>
  )
}
