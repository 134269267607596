import { resetTextAction } from 'actions/text/resetTextAction'
import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { appAgreementIndexPath } from 'components/paths'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import React, { FC } from 'react'
import AgreementIndex from '../Legal/Agreement/AgreementIndex'

export const AgreementBottomSheet: FC = () => {
  const dispatch = useAppDispatch()
  const baseUrl = useBaseUrl()

  const handleClosed = () => {
    dispatch(resetTextAction())
  }

  return (
    <BottomSheetOnRoute
      route={mergeAllUrls(baseUrl, appAgreementIndexPath)}
      onClosed={handleClosed}
    >
      <AgreementIndex redesign />
    </BottomSheetOnRoute>
  )
}
